<template>
    <div>
        <YellowButton
            url="/claims"
            text="PADĖSIU VIŠTOMS"
            big
            floating
            class="z-50 opacity-0"
        />

        <div class="relative z-20">
            <div
                class="h-[100px] rotate-180 lg:hidden"
                style="
                    background-image: url('/images/white-fuzzy-divider.png');
                    background-size: cover;
                    background-position: top;
                "
            ></div>

            <div class="absolute top-0 left-0 w-full flex justify-between items-center px-6 pt-4 pb-6 lg:flex-col lg:pt-8 xl:pt-12">
                <Logo />

                <h1 class="font-primary text-28 text-yellow text-center uppercase lg:mt-2 lg:text-60 lg:tracking-wider lg:text-center xl:text-88">
                    ĮKALINTOS NARVUOSE
                </h1>

                <p class="hidden text-center text-white text-24 tracking-wider lg:block lg:mt-2 xl:text-28">
                    Vištos, pasmerktos gyvenimui už grotų
                </p>
            </div>
        </div>

        <div class="relative">
            <div
                class="absolute w-full top-[-30px] z-10 pt-[40px] pb-[40px] lg:hidden"
                style="background: linear-gradient(180deg, #000000 62.79%, rgba(0, 0, 0, 0) 100%)"
            >
                <p class="text-center text-white text-14">
                    Vištos, pasmerktos gyvenimui už grotų
                </p>
            </div>

            <div class="relative">
                <video
                    id="index-video-1"
                    playsinline
                    autoplay
                    muted
                    loop
                    fetchpriority="high"
                    preload="metadata"
                    class="w-full"
                >
                    <source src="/images/video/hero-6sec.mp4" type="video/mp4">
                </video>
            </div>

            <div class="w-20 absolute z-30 top-[34%] right-[7%] lg:w-44 lg:top-[35%]">
                <HeroZoomButton @click="$vfm.show('hero-modal-3')" />
            </div>
            <div class="w-20 absolute z-30 top-[44%] left-[11%] lg:w-44 lg:top-[44%]">
                <HeroZoomButton @click="$vfm.show('hero-modal-1')" />
            </div>
            <div class="w-20 absolute z-30 top-[70%] left-[48%] lg:w-44 lg:top-[55%]">
                <HeroZoomButton @click="$vfm.show('hero-modal-2')" />
            </div>

            <div class="hidden !absolute bottom-[6%] left-1/2 -translate-x-1/2 lg:block">
                <YellowButton id="hero-cta-desktop" url="/claims" text="PADĖSIU VIŠTOMS" big />

                <p class="text-center text-14 text-white relative z-10 mt-2 lg:text-18 tracking-tight">
                    ...nes joms reikalinga mano pagalba.
                </p>
            </div>

            <div
                class="h-20 absolute w-full bottom-[-10px] lg:hidden"
                style="
                    background-image: url(/images/home-brown-bg-top-line.png);
                    background-size: 100% 350%;
                "
            ></div>

            <div
                class="h-20 absolute w-full bottom-[-50px] hidden lg:block"
                style="
                    background-image: url(/images/white-fuzzy-divider.png);
                    background-size: 400% 250%;
                    background-position: center top;
                "
            ></div>
        </div>

        <div
            class="relative pt-2 pb-20 mt-2 lg:hidden"
            style="
                background-image: url(/images/bg-brown.jpg);
                background-size: cover;
            "
        >
            <div class="flex justify-center relative z-10">
                <YellowButton id="hero-cta-mobile" url="/claims" text="Padėsiu vištoms" big />
            </div>

            <p class="text-center text-14 text-white relative z-10 mt-2">
                ...nes joms reikalinga mano pagalba.
            </p>

            <div
                class="h-20 absolute w-full bottom-[-40px]"
                style="
                    background-image: url(/images/white-fuzzy-divider.png);
                    background-size: 400% 250%;
                    background-position: center top;
                "
            ></div>
        </div>

        <div class="relative z-10">
            <p class="text-12 text-center tracking-tight lg:text-18 lg:mt-10">
                <a
                    @click="$vfm.show('modal-commitments');"
                    class="cursor-pointer hover:underline"
                >
                    Priimti įsipareigojimai
                </a>
            </p>

            <h2 class="mt-8 mx-auto font-primary text-red-100 text-36 text-center px-4 lg:mt-24 lg:text-60 lg:tracking-tight lg:max-w-5xl">
                Kaip gyvena narvuose laikomos vištos dedeklės?
            </h2>

            <img
                src="/images/illustration-farm-grid.png"
                class="block w-full mt-2 mx-auto lg:hidden"
            >

            <img
                src="/images/illustration-farm-grid-desktop.png"
                class="hidden w-full max-w-5xl mt-2 mx-auto lg:block"
            >

            <div class="flex justify-center mt-8">
                <YellowButton id="open-gallery-button" @click="$vfm.show('gallery')" text="Daugiau nuotraukų" big />
            </div>

            <h2 class="mt-8 mx-auto font-primary text-red-100 text-36 text-center px-4 lg:mt-40 lg:text-60 lg:tracking-tight lg:max-w-5xl">
                Ką turėtum žinoti apie narvuose laikomas vištas
            </h2>

            <p class="hidden text-center text-28 tracking-wider mt-4 lg:block ">
                Su jautriais gyvūnais elgiamasi kaip su <br/> kiaušinių dėjimo mašinomis.
            </p>

            <div class="lg:flex lg:flex-row-reverse lg:items-center lg:max-w-[90%] lg:max-w-7xl lg:mx-auto">
                <div class="lg:w-1/2 lg:overflow-visible">
                    <img
                        src="/images/illustration-caged-eggs.png"
                        class="block mt-2 w-[110%] lg:w-[150%] max-w-none"
                    >
                </div>

                <div class="text-center lg:text-left lg:w-1/2">
                    <h2 class="mt-8 font-primary text-red-100 text-39 px-4 lg:text-60 lg:tracking-tight">
                        KIAUŠINIAI, PAŽYMĖTI „NR. 3“
                    </h2>

                    <p class="text-24 tracking-wider px-5 mt-5 lg:text-33">
                        Atkeliavę iš industrinių paukštynų, kuriuose per pusantrų savo gyvenimo metų narvuose laikomos vištos niekada nepamato saulės šviesos ir nepajunta žemės po kojomis. Tokia yra kiaušinių, <a @click="$vfm.show('modal-number-3')" class="underline cursor-pointer">pažymėtų „Nr. 3“</a>, tikroji kaina. Narvuose vištos kenčia <a @click="$vfm.show('modal-number-3-stress')" class="underline cursor-pointer">patirdamos didžiulį stresą</a>, jų gerovė tokiomis sąlygomis negali būti užtikrinta. Bet tu gali tai pakeisti.
                    </p>
                </div>
            </div>

            <div class="lg:flex lg:items-center lg:max-w-[90%] lg:max-w-7xl lg:mx-auto">
                <div class="mt-9 px-5 lg:w-1/2">
                    <img
                        src="/images/illustration-earth.png"
                        class="lg:block lg:max-w-[90%]"
                    >
                </div>

                <div class="text-center lg:text-left lg:w-1/2">
                    <h2 class="mt-16 font-primary text-red-100 text-39 px-4 lg:text-60 lg:tracking-tight">
                        Pasaulis be vištų kančios
                    </h2>

                    <p class="text-24 tracking-wider px-5 mt-5 lg:text-33">
                        Įsivaizduok: pasaulis, kuriame nė viena višta dedeklė nelaikoma narve. Žmonės visame pasaulyje <a @click="$vfm.show('modal-united')" class="underline cursor-pointer">susivienijo</a>, kad ši vizija taptų realybe. Prisijunk prie jų ir tu – be tavęs tai nebus įmanoma.
                    </p>
                </div>
            </div>

            <div class="lg:flex lg:justify-end lg:relative">
                <div class="mt-20 pl-8 lg:w-1/2">
                    <img src="/images/illustration-europe.png" class="ml-auto">
                </div>

                <div class="lg:absolute container lg:left-1/2 lg:-translate-x-1/2">
                    <div class="lg:flex lg:items-center lg:max-w-[90%] lg:max-w-7xl lg:mx-auto">
                        <div class="text-center lg:text-left lg:w-1/2">
                            <h2 class="mt-16 font-primary text-red-100 text-39 px-4 lg:text-60 lg:tracking-tight">
                                Pokyčiai – tavo rankose
                            </h2>

                            <p class="text-24 tracking-wider px-5 mt-5 lg:text-33">
                                Tu turi didžiulę galią daryti įtaką kiaušinių industrijai. <a @click="$vfm.show('modal-more-and-more-countries')" class="underline cursor-pointer">Vis daugiau įmonių Europoje</a> atsisako narvuose laikomų vištų kiaušinių. Ne išimtis ir įmonės Lietuvoje, tačiau su tavo įsitraukimu jų gali būti dar daugiau. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="lg:flex lg:mt-56 lg:justify-center lg:items-center">
                <div>
                    <img
                        src="/images/illustration-hen-in-circles.png"
                        class="mt-10 mx-auto max-w-[240px] lg:max-w-[305px]"
                    >
                </div>

                <div class="lg:max-w-xl">
                    <p class="text-24 tracking-wider px-6 mt-5 lg:text-33">
                        Tu gali padėti gyvūnams dabar. Parodyk įmonėms, kad:
                    </p>

                    <ul class="text-14 pl-10 pr-10 mt-4 list-disc lg:text-18 lg:tracking-tight">
                        <li>
                            Tau rūpi kiekvienais metais narvuose laikomos 2,3 mln. vištų dedeklių.
                        </li>
                        <li class="mt-2">
                            Tau rūpi tai, kas slepiama už storų fermų sienų.
                        </li>
                        <li class="mt-2">
                            Tau rūpi, kokį gyvenimą šios vištos verčiamos patirti.
                        </li>
                        <li class="mt-2 font-bold">
                            Tu atsisakai remti tokį vištų dedeklių laikymo būdą, kai paukščiai yra priversti kentėti kiekvieną savo gyvenimo dieną.
                        </li>
                    </ul>
                </div>
            </div>

            <div
                id="footer"
                class="relative z-10 bg-[url(/images/footer-bg.png)] bg-repeat-x bg-cover pt-16 pb-8 mt-4 lg:mt-32 lg:pt-24 lg:pb-16"
            >
                <div class="flex justify-center">
                    <YellowButton url="/claims" text="PADĖSIU VIŠTOMS" with-hearts big />

                    <img
                        src="/images/arrow-l.svg"
                        class="absolute left-0 top-[-20%] max-w-[70px] lg:left-[22%] lg:max-w-none"
                    >

                    <img
                        src="/images/arrow-r.svg"
                        class="absolute right-0 top-[-70%] max-w-[110px] -rotate-[16deg] lg:right-[15%] lg:top-[-50%] lg:max-w-none lg:rotate-0"
                    >
                </div>

                <div class="mt-16 flex flex-wrap justify-center items-center gap-y-2 text-12 text-white lg:divide-x lg:divide-white">
                    <div class="px-5">
                        <a href="https://www.tustinarvai.lt" target="_blank">
                            <img src="/images/logo/logo-white.png" class="inline-block max-h-[30px]">
                        </a>
                    </div>
                    <p class="px-5 py-2"><a @click="$vfm.show('modal-photo-authors')" class="hover:underline cursor-pointer">Nuotraukų autoriai</a></p>
                    <p class="px-5 py-2">Ceikinių g. 3-4 Vilnius</p>
                    <p class="px-5 py-2"><a @click="$vfm.show('modal-privacy-policy')" class="hover:underline cursor-pointer">Privatumo politika</a></p>
                    <p class="px-5 py-2"><a href="mailto:info@tustinarvai.lt" class="hover:underline cursor-pointer">info@tustinarvai.lt</a></p>
                    <p class="px-5 py-2"><a href="tel:+37068929488" class="hover:underline cursor-pointer">+370 689 29488</a></p>
                </div>

                <p class="mt-6 px-5 text-white max-w-[700px] mx-auto text-12 text-center">
                    Puslapyje pateiktos nuotraukos bei vaizdo įrašai atskleidžia realias vištų laikymo narvuose sąlygas Lietuvoje bei kitose Europos šalyse.
                </p>

                <div class="flex justify-center mt-6">
                    <a href="http://www.bugsnag.com/" target="_blank" class="block">
                        <img v-lazy="`/images/bugsnag-logo.png`" class="w-32">
                    </a>
                </div>
            </div>
        </div>

        <HeroPopup
            name="hero-modal-1"
            image-url="/images/hero-popup-1.jpeg"
            desktop-image-offset="70%"
            title="NEGALI IŠSKLEISTI SPARNŲ"
            text="Narve vištai tenka A4 lapo plotas. Per pusantrų savo gyvenimo metų ji niekada iki galo neišskleis sparnų – nespėjusi jų nė ištiesti, ji įsiremia į šalia esančias grotas ar kitas vištas. Ji niekada nepamatys saulės, didžiąją dalį laiko praleis prieblandoje, smarvėje ir purve, apsupta kitų, tą patį patiriančių gyvūnų."
            solution="LAISVĖ JUDĖTI IR IŠREIKŠTI SAVO NATŪRALŲ ELGESĮ"
            button-text="AŠ NORIU, KAD VIŠTOS <br/> GALĖTŲ IŠSKLEISTI SPARNUS"
        />
        <HeroPopup
            name="hero-modal-2"
            image-url="/images/hero-popup-3.jpeg"
            title="NEGALI APSIGINTI NUO STIPRESNIŲ"
            text="Negalėdamos patenkinti tokių esminių poreikių kaip kapstyti žemės ar sukti lizdo, vištos patiria nuolatinį stresą, todėl gali pradėti kapoti viena kitą. Narve silpnesniems gyvūnams nėra galimybės pabėgti."
            solution="FERMERIAI NEBELAIKYS VIŠTŲ DEDEKLIŲ NARVUOSE"
            button-text="AŠ NORIU, KAD VIŠTOS <br/> GALĖTŲ PASISLĖPTI"
        />
        <HeroPopup
            name="hero-modal-3"
            image-url="/images/hero-popup-2.jpeg"
            desktop-image-offset="60%"
            title="VIELOS SUKELIA SUŽALOJIMUS"
            text="Narve laikoma višta visą gyvenimą stovi ant nuožulnių vielinių grotų, kad jos dedami kiaušiniai nuriedėtų į surinkimo vietą. Dėl to vištos kojos suaižėja, atsiranda skausmingos, atviros žaizdos. Kad pasiektų pašarą, ji kaskart turi iškišti kaklą pro grotas. Nuolatos besitrinant į narvą vištai nulūžta plunksnos ir ji lieka pusnuogė."
            solution="KRAIKAS ARBA ŽEMĖ PO KOJOMIS, LAISVĖ NUO VARŽANČIO GYVENIMO NARVE"
            button-text="AŠ NORIU, KAD PAUKŠČIAI <br/> NEBŪTŲ ĮKALINTI TARP GROTŲ"
        />
        <Modal
            name="modal-commitments"
            type="gallery"
            extra-content-class="max-w-[1087px] pb-0 px-6 h-full"
        >
            <h3 class="font-primary text-24 tracking-tight border-b border-b-gray-100 pb-4 lg:text-33">
                Įmonės, deklaravusios, kad nutrauks, arba jau nutraukusios narvuose laikomų vištų kiaušinių naudojimą
            </h3>

            <div class="mt-6 grid grid-cols-2 pb-6 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4">
                <div
                    v-for="(name, path) in commitments"
                    :key="path"
                    class="flex flex-col text-center"
                >
                    <div class="flex-grow flex justify-center items-center">
                        <img
                            v-lazy="`/images/commitments/${path}`"
                            :alt="name"
                            class="w-4/5"
                        />
                    </div>

                    <span class="inline-block mt-2 text-14 lg:text-18">
                        {{ name }}
                    </span>
                </div>
            </div>
        </Modal>
        <Modal name="modal-more-and-more-countries" extra-content-class="simple-text-modal">
            Beveik 1 400 įmonių Europoje jau įsipareigojo atsisakyti narvuose laikomų vištų kiaušinių.
        </Modal>
        <Modal name="modal-number-3" extra-content-class="simple-text-modal">
            <p>Kiaušinių žymėjimas numeriais 3, 2, 1 ar 0 nurodo, kokiomis sąlygomis laikomos kiaušinius dedančios vištos. Kuo mažesnis skaičius – tuo geresnės sąlygos.</p>
            <p>„Nr. 3“ – narvuose laikomos vištos, mažai erdvės, narve neįmanoma net apsisukti.</p>
            <p>„Nr. 2“ – ant kraiko laikomos vištos, daugiau erdvės, vištos gali judėti ir išskleisti sparnus.</p>
            <p>„Nr. 1“ – laisvai laikomos vištos, daugiau erdvės, vištos gali išeiti į lauką.</p>
            <p>„Nr. 0“ – ekologiškai laikomos vištos, sveikas pašaras, daug erdvės, vištos gali išeiti į lauką.</p>
        </Modal>
        <Modal name="modal-number-3-stress" extra-content-class="simple-text-modal">
            <p>Kiekviena diena vištoms dedeklėms narvuose reiškia kentėjimą. Jos negali patenkinti esminių savo poreikių – kapstyti žemės, praustis smėlyje ar tyrinėti aplinkos, susisukti lizdo ar net išskleisti savo sparnų. Tai joms kelia didžiulį stresą, todėl jos ima kapoti viena kitą. Tačiau narve silpnesnės neturi kur pasislėpti nuo stipresnių. Tam, kad vištų dedami kiaušiniai nuriedėtų į surinkimo vietas, vištoms dedeklėms tenka stovėti ant kojas aižančių, nuožulnių vielų. Įprastai gyvendamos iki 10 metų, narvuose laikomos vištos „susidėvi“ per pusantrų metų laikotarpį, tada „atidirbusios“ savo, nusilpusios ir išsigandusios, jos yra išvežamos į skerdyklas.</p>

            <YellowButton url="/claims" text="PADĖSIU VIŠTOMS" class="mx-auto" />
        </Modal>
        <Modal name="modal-united" extra-content-class="simple-text-modal">
            <p>Virš 90 organizacijų iš 67 pasaulio šalių susivienijo, kad išlaisvintų narvuose laikomas vištas dedekles. Negana to, 1,4 mln. Europos Sąjungos piliečių pasirašė Europos piliečių iniciatyvą „Pabaiga narvų erai“ (angl. "End the Cage Age"), kuria siekiama uždrausti narvų naudojimą pramoninėse fermose. Dabar Europos Komisija svarsto uždrausti gyvūnų, įskaitant ir vištų dedeklių, laikymą narvuose. Parodyk, kad tai privalo tapti realybe.</p>
        </Modal>
        <Modal
            name="gallery"
            type="gallery"
            extra-content-class="!bg-[url(/images/bg-gallery-modal.jpeg)] !bg-repeat !bg-cover !p-0 !max-w-full !max-h-full !border-0 !rounded-none !m-0 h-full"
        >
            <div class="overflow-hidden lg:max-w-5xl mx-auto pb-10">
                <template v-for="(file, index) in gallery" :key="index">
                    <div
                        :id="`gallery-item-${index}`"
                        class="relative"
                        :class="{
                            'gallery-image': file.type === 'image',
                            'gallery-video': file.type === 'video',
                        }"
                    >
                        <div
                            v-if="file.sensitive"
                            @click="reveal(index)"
                            class="sensitive-overlay absolute inset-0 z-50 flex flex-col justify-center items-center cursor-pointer"
                        >
                            <img src="/images/icons/reveal.svg">
                            <p class="text-14 text-white font-[500] tracking-tight sm:text-16 lg:text-20">
                                Spausk, kad pamatytum
                            </p>
                        </div>

                        <div
                            class="duration-300"
                            :class="{
                                'blur-lg': file.sensitive,
                            }"
                        >
                            <template v-if="file.type === 'image'">
                                <img v-lazy="file.path" :alt="file.label">
                            </template>

                            <template v-if="file.type === 'video'">
                                <div
                                    @click="playVideo(index)"
                                    class="gallery-video-wrapper relative"
                                >
                                    <video
                                        :id="`gallery-video-${index}`"
                                        preload="metadata"
                                        playsinline
                                        muted
                                        loop
                                    >
                                        <source :src="file.path" type="video/mp4">
                                    </video>

                                    <div class="overlay absolute inset-0 bg-gray-bg-05 cursor-pointer"></div>

                                    <img
                                        src="/images/icons/play-video.svg"
                                        class="icon-play-video absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                                    >
                                </div>
                            </template>
                        </div>
                    </div>

                    <p class="text-white text-14 px-4 mt-2 mb-6 min-h-[20px] lg:text-20">
                        {{ file.label }}
                    </p>
                </template>
            </div>
        </Modal>
        <Modal name="modal-photo-authors" extra-content-class="simple-text-modal">
            Tušti narvai, Dzivnieku briviba, Konrad Lozinski, Andrew Skowron, Equalia, OBRAZ. 
        </Modal>
        <PrivacyPolicyModal/>
    </div>
</template>

<script setup>
    import { $vfm } from 'vue-final-modal';
    import Tracker from '~/src/Tracker';

    const commitments = {
        'lidl.png': 'LIDL (iki 2025-ųjų)',
        'narvesen.png': 'Narvesen (iki 2025-ųjų)',
        'assorti.png': 'Assorti',
        'rimi.png': 'Rimi (iki 2025-ųjų)',
        'maxima.png': 'Maxima (iki 2025-ųjų)',
        'norfa.png': 'Norfa (iki 2025-ųjų)',
        'silas.png': 'Šilas (iki 2025-ųjų)',
        'express-market.png': 'Express Market (iki 2025-ųjų)',
        'iki.png': 'IKI (iki 2025-ųjų)',
        'ikea.png': 'IKEA',
        'cia-market.png': 'Čia Market (iki 2025-ųjų)',
        'holiday-inn.png': 'Holiday Inn',
        'ibis.png': 'Ibis',
        'novotel.png': 'Novotel',
        'crowne-plaza.png': 'Crowne Plaza',
        'panorama-hotel.gif': 'Panorama (iki 2023-ųjų)',
        'amberton.jpg': 'Amberton (iki 2025-ųjų)',
        'congress.jpg': 'Congress (iki 2023-ųjų)',
        'narutis.png': 'Narutis (iki 2025-ųjų)',
        'bw.png': 'Best Western (iki 2025-ųjų)',
        'artis.jpg': 'Artis',
        'mabre-residence.png': 'Mabre Residence',
        'mercure.png': 'Mercure',
        'ratonda.jpg': 'Ratonda',
        'imperial.jpg': 'Imperial Hotel & Restaurant',
        'stikliai.png': 'Stikliai (iki 2025-ųjų)',
        'kempinski.png': 'Kempinski Hotel',
        'vilnius-grand-resort.png': 'Vilnius Grand Resort (iki 2025-ųjų)',
        'best-baltic.jpg': 'Best Baltic Hotels',
        'hilton.png': 'Hilton Worldwide (iki 2025-ųjų)',
        'green-hotels.png': 'Green Hotels (iki 2024-ųjų)',
        'kaunas-hotel.png': 'Kaunas Hotel',
        'vanagupe.png': 'Vanagupė',
        'kaunas-city-hotel.jpg': 'Viešbutis „Kaunas city"',
        'shakespeare.gif': 'Shakespeare Restaurant & Bar',
        'life-balance.jpg': 'Palanga SPA Life Balance Hotel',
        'radisson.jpg': 'Radisson Hotel Group (iki 2025-ųjų)',
        'comfort.png': 'Comfort Hotel LT - Rock \'n\' Roll Vilnius (iki 2025-ųjų)',
        'mcdonalds.png': 'McDonald\'s',
        'subway.jpg': 'Subway',
        'cili.png': 'Čili Pizza (iki 2023-ųjų)',
        'charlie-pizza.png': 'Charlie Pizza',
        'carskoje.png': 'Carskoje Selo',
        'la-crepe.jpg': 'La Crepe',
        'katpedele.png': 'Katpėdėlė',
        'fortas.png': 'Fortas',
        'caffeine.png': 'Caffeine Roasters',
        'manami.jpg': 'Manami',
        'can-can.png': 'CanCan Pizza',
        'soya.png': 'SOYA (iki 2023-ųjų)',
        'caif-cafe.png': 'Caif Cafe',
        'vapiano.png': 'Vapiano',
        'dziaugsmas.png': 'Džiaugsmas',
        'stebuklai.jpg': 'Stebuklai',
        'delano.jpg': 'DELANO',
        'busi-trecias.jpg': 'Būsi trečias',
        'dirty-duck.jpg': 'Dirty Duck',
        'gringo.jpg': 'Gringo Pub',
        'pianoman.jpg': 'Pianoman Bar',
        'sushi-city.jpg': 'Sushi City',
        'holy-donut.jpg': 'Holy Donut',
        'jurgis-ir-drakonas.png': 'Jurgis ir drakonas',
        'brooklyn-brothers.jpg': 'Brooklyn Brothers',
        'drama-burger.png': 'Drama Burger',
        'saules-jegaine.jpg': 'Saulės jėgainė',
        'grill-london.jpg': 'Grill London',
        'alaus-namai.png': 'Alaus namai',
        'panama.jpg': 'Panama Food Garden',
        'pjaz.jpg': 'PJazz',
        'miyako.png': 'Miyako',
        'oldman.png': 'Oldman',
        'valgomasis.jpg': 'Valgomasis',
        'verkiai.jpg': 'Restoranas „Verkiai“',
        'time.jpg': 'Time (iki 2025-ųjų)',
        '14-horses.jpg': '14Horses Brasserie',
        'city-chef.jpg': 'City Chef Barbecue',
        'kablys-jura.jpg': 'Kablys+jūra',
        'delta-mityba.png': 'Delta mityba',
        'vilkine.jpg': 'Vilkinė',
        'vathaitau.jpg': 'Vathaitau',
        'pietausim.jpg': 'Pietausim',
        'busy-beet.png': 'Busy Beet',
        'miske.jpg': 'Miške',
        'blue-lotus.jpg': 'Blue Lotus',
        'mondelez.jpg': 'Mondelez (iki 2025-ųjų)',
        'unilever.png': 'Unilever',
        'mars.png': 'Mars',
        'ferrero.png': 'Ferrero',
        'pepsico.png': 'PepsiCo (iki 2025-ųjų)',
        'danone.png': 'Danone',
        'mon-ami.png': 'Mon Ami',
        'daumantu.jpg': 'Daumantų (iki 2025-ųjų)',
        'crustum.png': 'Crustum (iki 2025-ųjų)',
        'thierr.jpg': 'Thierry kepykla (iki 2025-ųjų)',
        '101-kepyklele.png': '101 kepyklėlė (iki 2025-ųjų)',
        'nestle.png': 'Nestle',
        'birzu-duona.jpg': 'Biržų duona',
        'prezo.jpg': 'Prezo (iki 2025-ųjų)',
        'fazer.jpg': 'Fazer Lietuva (iki 2025-ųjų)',
        'vilniaus-duona.png': 'Vilniaus duona',
        'dzukija.jpg': 'Dzūkija',
        'pitlius.jpg': 'Pitlius',
        'mantinga.jpg': 'Mantinga (iki 2025-ųjų)',
        'klaipedos-duona.png': 'Klaipėdos duona (iki 2025-ųjų)',
        'puratos.jpg': 'Puratos Lietuva (iki 2025-ųjų)',
        'baltasis-pyragas.png': 'Baltasis pyragas (iki 2023-ųjų)',
        'vijokliai.jpeg': 'Vijokliai',
        'burger-king.png': 'Burger King (iki 2025-ųjų)',
        'marriott.png': 'Marriott (iki 2025-ųjų)',
        'oetker.png': 'Dr. Oetker',
        'avs.png': 'AVS Prekyba (iki 2025-ųjų)',
        'grata.png': 'Grata',
        'guacamole.jpeg': 'Guacamole',
        'pinavija.png': 'Pinavija',
        'hesburger.svg.png': 'Hesburger',
        'kavos-era.png': 'Kavos era',
        'kitchen.png': 'Kitchen',
        'minordija.png': 'Minordija (iki 2025-ųjų)',
        'monika.png': 'Monika Centrum Hotel',
        'nineteen18.png': 'Nineteen18',
        'fire-place.png': 'Fire Place',
        'rib-room.png': 'Rib Room',
        'sugamour.png': 'Sugamour',
        'taste-map.png': 'Taste Map',
        'vanilinis-dangus.jpeg': 'Vanilinis dangus',
        'Kedainiu k.png': 'Kėdainių konservų fabrikas',
        'Le travi.jpeg': 'Le Travi',
        'Mikotel.png': 'Mikotel',
        'vilniaus-majonezas.png': 'Vilniaus majonezas',
        'veiveriu-skonis.png': 'Veiverių skonis',
        'AMANDUS_Žalias.png': 'Amandus',
    };

    const gallery = [
        {
            type: 'image',
            path: '/images/gallery/29907467084_89d2f97f79_o.jpg',
            label: 'Industrinės fermos narve laikoma višta dedeklė.',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/29907528444_9f001fe313_o.jpg',
            label: 'Tamsūs ir dvokiantys fermos angarai.',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/29907490554_bfaf5df61b_o.jpg',
            label: 'Mirusios vištos išmetamos kaip šiukšlės.',
            sensitive: true,
        },
        {
            type: 'image',
            path: '/images/gallery/30450902021_20f0e3145d_o.jpg',
            label: 'Tikroji kiaušinių „Nr. 3“ kaina.',
            sensitive: true,
        },
        {
            type: 'image',
            path: '/images/gallery/46575817915_2da48fe22c_o.jpg',
            label: 'Ši višta pusantrų savo gyvenimo metų praleis tarp grotų.',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/47801864522_52d45af5fb_o.jpg',
            label: 'Višta vertinga tiek pat, kiek ir jos kiaušiniai.',
            sensitive: true,
        },
        {
            type: 'image',
            path: '/images/gallery/48797930517_5f59889fc1_o.jpg',
            label: 'Jautrūs gyvūnai paverčiami kiaušinių dėjimo mašinomis.',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/49209302398_cef6ca73f4_o.jpg',
            label: 'Ne visos vištos ištveria 18 gyvenimo narve mėnesių.',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/IMG_0033.jpg',
            label: 'Nusilpusios ir perbalusios, vištos tampa sveikų gyvūnų šešėliais.',
            sensitive: false,
        },
        {
            type: 'video',
            path: '/images/gallery/bugs.mp4',
            label: 'Erkės ant kiaušinių. Kiaušiniai fermose neplaunami ir nevalomi, kad ilgiau negestų. ',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/49210007097_a34eb62147_o.jpg',
            label: 'Gyvendamos narve, jos niekada neišskleis savo sparnų.',
            sensitive: false,
        },
        {
            type: 'video',
            path: '/images/gallery/erkes.mp4',
            label: 'Narvuose vištos neturi galimybės išsivalyti parazitų, dėl to jas nuolat puola raudonosios erkės.',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/49217382922_cf2434bfe4_o.jpg',
            label: 'Ši višta, kaip ir visos likusios, pasmerkta niūriam likimui.',
            sensitive: false,
        },
        {
            type: 'image',
            path: '/images/gallery/49216674038_a254a19f0a_o.jpg',
            label: 'Besitrindamos į grotas, vištos netenka savo plunksnų.',
            sensitive: false,
        },
        {
            type: 'video',
            path: '/images/gallery/kloaka.mp4',
            label: 'Sergančios vištos dažnai ilgą laiką nepastebimos ir paliekamos kentėti.',
            sensitive: true,
        },
        {
            type: 'image',
            path: '/images/gallery/IMG_0049.jpg',
            label: 'Atidavusi savo energiją ir sveikatą, višta miršta šalia padėto kiaušinio.',
            sensitive: false,
        },
    ];

    function playVideo(index) {
        const $video = $(`#gallery-video-${index}`);
        const $wrapper = $video.parents('.gallery-video-wrapper');

        $video[0].play();
        $wrapper.addClass('is-playing');
    }

    function reveal(index) {
        const $item = $(`#gallery-item-${index}`);

        $item.find('.sensitive-overlay').hide();
        $item.find('.blur-lg').removeClass('blur-lg');
    }

    onMounted(() => {
        Tracker.pixelPageOpen('home');

        function toggleCtaVisibility() {
            const $floater = $('.floating-button');
            const hideWhenVisible = [
                '#hero-cta-desktop',
                '#hero-cta-mobile',
                '#footer',
            ];
            const hideWhenOverlaps = [
                '#open-gallery-button',
            ];

            if ($floater.length) {
                const windowTop = $(window).scrollTop();
                const windowBottom = windowTop + $(window).height();

                let shouldShowFloater = true;

                for (let i = 0; i < hideWhenVisible.length; i++) {
                    let $hideWhenVisible = $(hideWhenVisible[i]);

                    if ($hideWhenVisible.length) {
                        let hideWhenVisibleTop = $hideWhenVisible.offset().top;
                        let hideWhenVisibleBottom = hideWhenVisibleTop + $hideWhenVisible.outerHeight();
                        if (windowTop < hideWhenVisibleBottom && windowBottom > hideWhenVisibleTop - 50) {
                            shouldShowFloater = false;
                            console.log(hideWhenVisible[i], 'is visible');
                            break;
                        }
                    }
                }

                for (let k = 0; k < hideWhenOverlaps.length; k++) {
                    let $hideWhenOverlaps = $(hideWhenOverlaps[k]);

                    if ($hideWhenOverlaps.length) {
                        let hideWhenOverlapsTop = $hideWhenOverlaps.offset().top;
                        let hideWhenOverlapsBottom = hideWhenOverlapsTop + $hideWhenOverlaps.outerHeight();
                        let ctaFloatingTop = $floater.offset().top;
                        let ctaFloatingBottom = ctaFloatingTop + $floater.outerHeight();

                        if (ctaFloatingTop < hideWhenOverlapsBottom + 50 && ctaFloatingBottom > hideWhenOverlapsTop - 50) {
                            shouldShowFloater = false;
                            break;
                        }
                    }
                }

                // console.log({ shouldShowFloater });

                if (shouldShowFloater) {
                    $floater.css('opacity', '100');
                    $floater.css('z-index', '50');
                    // $landingPopup2.addClass('overlayed');
                    // $landingPopup3.addClass('overlayed');
                } else {
                    $floater.css('opacity', '0');
                    $floater.css('z-index', '-10');
                    // $landingPopup2.removeClass('overlayed');
                    // $landingPopup3.removeClass('overlayed');
                }
            }
        }

        $(window).scroll(toggleCtaVisibility);
        toggleCtaVisibility();
        $('.floating-button').removeClass('opacity-0');

        // $vfm.show('modal-commitments');
        // $vfm.show('gallery');
    });
</script>
