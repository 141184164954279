<template>
    <div class="group inline-block cursor-pointer">
        <img
            src="/images/hero-zoom-button.png"
            fetchpriority="high"
            class="group-hover:hidden w-full"
        >

        <img
            src="/images/hero-zoom-button-hover.png"
            fetchpriority="high"
            class="hidden group-hover:inline w-full"
        >
    </div>
</template>
