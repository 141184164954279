<template>
    <vue-final-modal
        v-slot="{ params, close }"
        v-bind="$attrs"
        v-model="open"
        classes="flex justify-center items-center"
        :content-class="`relative w-full h-full`"
    >
        <div
            class="flex flex-col justify-between bg-repeat h-[100vh] overflow-y-auto lg:hidden"
            style="background-image: url(/images/popup-bg-middle.jpg)"
        >
            <!-- [1/3] photo -->
            <div class="relative flex-grow min-h-[50vw]">
                <img
                    :src="imageUrl"
                    class="absolute w-full h-full object-cover flex-shrink"
                >
            </div>

            <div>
                <!-- [2/3] main text -->
                <div class="relative pt-5">
                    <img
                        src="/images/popup-bg-middle-top-divider.png"
                        class="absolute w-full top-[-50px] xs:top-[-80px] md:top-[-120px]"
                    >

                    <div class="px-5 text-center text-white pb-12 z-10 relative max-w-[700px] mx-auto">
                        <h3 class="font-primary text-16 tracking-wider">
                            {{ title }}
                        </h3>

                        <p class="mt-2 text-14">
                            {{ text }}
                        </p>
                    </div>
                </div>

                <!-- [3/3] solution -->
                <div class="relative w-full pt-16 pb-10">
                    <img src="/images/popup-bg-bottom.png" class="w-full h-full absolute top-0 left-0 right-0">

                    <div class="absolute top-[-25px] left-1/2 -translate-x-1/2">
                        <img src="/images/popup-bg-circle.svg">
                        <img
                            src="/images/popup-bulb.svg"
                            class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 max-w-[35px]"
                        >
                    </div>

                    <div class="px-5 text-14 tracking-wider text-center relative uppercase max-w-[350px] mx-auto">
                        <span class="font-bold">Sprendimas: </span>{{ solution }}
                    </div>

                    <YellowButton
                        url="/claims"
                        text="PADĖSIU VIŠTOMS"
                        class="mt-4 mx-auto"
                    />
                </div>
            </div>
        </div>

        <div class="hidden lg:flex h-full">
            <div
                class="w-full h-full"
                :style="`background-image: url(${imageUrl}); background-size: cover; background-position: ${desktopImageOffset}, top;`"
            ></div>

            <div
                class="relative -ml-20 w-full max-w-[700px] pr-16 pl-32 overflow-y-auto pt-16 pb-16"
                style="background-image: url(/images/popup-desktop-right-bg.png)"
            >
                <div>
                    <h3 class="font-primary text-36 tracking-tight text-white text-center">
                        {{ title }}
                    </h3>

                    <p class="mt-8 text-18 tracking-tight text-white text-center">
                        {{ text }}
                    </p>

                    <div class="mt-16 pt-24 pb-10 relative">
                        <img
                            src="/images/popup-desktop-bg-white-with-bulb.png"
                            class="absolute w-full h-full inset-0"
                        >

                        <div class="max-w-[470px] flex flex-col items-center justify-center mx-auto relative z-50">
                            <div class="text-center text-18 uppercase">
                                <span class="font-bold">SPRENDIMAS: </span>{{ solution }}
                            </div>

                            <YellowButton
                                url="/claims"
                                :text="buttonText"
                                text-class="text-20"
                                class="mt-4 mx-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <img
            @click="close()"
            src="/images/popup-close-button.png"
            class="absolute top-0 right-0 w-[64px] cursor-pointer z-50"
        >

        <slot name="close" :close="() => close()" />
    </vue-final-modal>
</template>

<script setup>
    import { VueFinalModal } from 'vue-final-modal';

    const props = defineProps({
        imageUrl: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        solution: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
        desktopImageOffset: {
            type: String,
            default: '50%',
        },
    });

    const open = ref(false);
</script>
